
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import newStartersSearchOptions from '@/modules/NewStarters/searchOptions';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import { NewStartersOptionsType } from '@/api/graphQL/graphNodes/types';
import { Debounced } from '@/utils/debounced';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import system from '@/modules/System';

@Component({
    components: {},
})
export default class NewStartersHeader extends Vue {
    @Sync(newStartersSearchOptions) private query!: string;

    @Get(newStartersSearchOptions) private loading!: boolean;

    @Get(newStartersSearchOptions) private minSearchLength!: number;

    @Get(newStartersSearchOptions) private options!: NewStartersOptionsType[];

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private displaySearch!: boolean;

    @Get(newStarters) private type!: string;

    @Sync(newStarters) private dateStart!: string;

    @Sync(newStarters) private dateEnd!: string;

    @Get(newStarters) private newStartersTimeFrame!: string;

    @Get(system) private screenType!: string;

    get isMobile(): boolean {
        return this.screenType === 'mobile';
    }

    get typeOrganisational() {
        return newStarters.typeOrganisational;
    }

    get typeCentralTeam() {
        return newStarters.typeCentralTeam;
    }

    get typePersonal() {
        return newStarters.typePersonal;
    }

    get thisMonth() {
        return newStarters.typeThisMonth;
    }

    get last60days() {
        return newStarters.typeLast60Days;
    }

    get last90days() {
        return newStarters.typeLast90Days;
    }

    get customInterval() {
        return newStarters.typeCustom;
    }

    get searchOptions(): SelectOptionsType[] {
        return this.options.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'pts-small-size-100',
            'NewStartersHeader__Container',
        ];
    }

    get timeFrame() {
        return newStarters.timeFrame;
    }

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get disableDatepickers(): boolean {
        return this.newStartersTimeFrame !== this.customInterval;
    }

    handleSelect(val) {
        const { value } = val;
        newStartersSearchOptions.setQuery(value);
        newStarters.setDisplaySearchResults(true);
        newStarters.search({ query: value });
    }

    @Debounced(1000)
    async handleChange() {
        if (this.query === '') {
            newStarters.setDisplaySearchResults(false);
            newStartersSearchOptions.setOptions([]);
            newStarters.setSearchedLegs([]);
            this.handleGetNewStarters();
        } else if (this.query.length >= this.minSearchLength) {
            const { type, timeFrame } = this;
            await newStartersSearchOptions.search({ timeFrame, type });
        }
    }

    handleType(val: string) {
        newStarters.setType(val);
        const { query, displaySearchResults } = this;

        if (displaySearchResults) {
            newStarters.search({ query });
        } else {
            this.handleGetNewStarters();
        }
    }

    handleTimeFrame(val: string) {
        newStarters.setNewStartersTimeFrame(val);

        if (val !== this.customInterval) {
            const interval = newStarters.timeFrame;
            newStarters.setStartDate(interval.from);
            newStarters.setEndDate(interval.to);

            this.loadLegs();
        }
    }

    loadLegs() {
        const { query, displaySearchResults } = this;

        if (displaySearchResults) {
            newStarters.search({ query });
        } else {
            this.handleGetNewStarters();
        }
    }

    handleGetNewStarters() {
        newStarters.getData({ loadInBackground: true });
    }
}
