
import { Component, Prop } from 'vue-property-decorator';
import { EventType, InvitationToEventType } from '@/api/graphQL/graphNodes/types';
import { EVENT_ATTENDANCE_STATUS, EVENT_INVITATION_TYPE } from '@/modules/Event/constants';
import Vue from 'vue';
import CurrencyPrefix from '@/utils/currencyPrefix';
import dateManager from '@/utils/time';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';

@Component
export default class DayViewEvent extends Vue {
    @Prop() private event!: EventType;

    get eventStartTime(): string {
        return dateManager.getDateTime(this.event.eventDateFrom, { returnFormat: 'HH:mm' });
    }

    get eventTime(): string {
        const endDateFormat = dateManager
            .isSameDay(this.event.eventDateFrom, this.event.eventDateTo)
            ? 'HH:mm' : 'dddd, MMMM D HH:mm';

        return `${dateManager.getDateTime(this.event.eventDateFrom, { returnFormat: 'dddd, MMMM D HH:mm' })}
         - ${dateManager.getDateTime(this.event.eventDateTo, { returnFormat: endDateFormat })}`;
    }

    get invitations(): InvitationToEventType[] {
        return this.event.invitations
            .filter((item) => item.type !== EVENT_INVITATION_TYPE.hostess);
    }

    get eventTotalGuests(): number {
        return this.invitations.length;
    }

    get eventGuestsStatus() {
        const { invitations } = this;

        const goingGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.yes)
            .length;

        const tentativeGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.tentative)
            .length;

        const awaitingGuests: number = invitations
            .filter((invitation) => !invitation.attending)
            .length;

        const declinedGuests: number = invitations
            .filter((invitation) => invitation.attending === EVENT_ATTENDANCE_STATUS.declined)
            .length;

        return `${goingGuests} yes, ${tentativeGuests} tentative, ${awaitingGuests} awaiting, ${declinedGuests} declined`;
    }

    get totalSales(): number {
        return this.event.totalSales;
    }

    get eventItemClasses() {
        return [
            'DayViewEvent__Item',
            { 'DayViewEvent__Item--cancelled': this.event.canceled },
            { 'DayViewEvent__Item--closed': this.event.closed },
            'pts-layout',
            'pts-gutter',
        ];
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    openEventView() {
        this.$router.push({ name: 'eventView', params: { id: this.event.id } });
    }
}
