const countries = `type Countries {
    longLabel: String!
    shortLabel: String!
    phoneCode: String!
    requiredBusinessDetails: [String!]!
    disabled: Boolean!
}`;

const countriesQuery = `
    countries: [Countries]
    regions: [Countries]
`;

export { countries, countriesQuery };
