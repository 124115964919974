
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import register from '@/modules/Register';
import countries from '@/modules/Countries';
import registerProfilePicture from '@/modules/Register/registerProfilePicture';
import authenticator from '@/modules/Auth/services/authenticator';
import { LEXIS_NEXIS_STATUS } from '@/modules/Register/constants';
import addressSelect from '@/modules/AddressSelect';
import { CountriesType } from '@/modules/Event/types';
import { RegisterAmbassadorFormType } from '@/api/graphQL/graphNodes/types';
import { ObjectProcessor } from '@plumtreesystems/utils';
import Content from './Content/index.vue';
import CanNotRegister from './CanNotRegister/index.vue';

@Component({
    components: {
        Content,
        CanNotRegister,
    },
})
export default class Register extends Vue {
    @Get(register) private loading!: boolean;

    @Get(register) private formData!: RegisterAmbassadorFormType;

    @Get(countries) private countriesLoading!: boolean;

    @Get(register) private canRegister!: boolean;

    @Get(register) private lexisNexisStatus!: string|null;

    get contentLoaded(): boolean {
        return !this.loading && !this.countriesLoading;
    }

    get regions(): CountriesType[] {
        return countries.enabledRegionsList;
    }

    get token(): string {
        const { token } = this.$route.query;
        return token.toString();
    }

    async mounted() {
        addressSelect.resetData();
        const { logged } = this.$route.query;

        if (logged !== 'true') {
            await authenticator.logout(false);
        }
        registerProfilePicture.clearImageData();
        register.clearFormData();
        register.clearFormErrors();
        register.setDisableBusinessDetails(false);
        register.setImageDialogOpen(false);
        register.setCameraDialogOpen(false);
        await Promise.all([
            register.getUserData(this.token),
            countries.getRegions(this.token),
        ]);

        this.handleSetPhoneCodeByCountry();
    }

    handleSetPhoneCodeByCountry() {
        if (this.formData.phoneNumber === '') {
            const region = this.regions.find((item) => item.shortLabel === this.formData.region);

            if (region) {
                const res = ObjectProcessor.setPropertyByValue('phoneNumberCode', region.phoneCode, this.formData);
                register.setFormData(res);
            }
        }
    }

    @Watch('lexisNexisStatus')
    handleAdditionalForm(): void {
        if (this.lexisNexisStatus === LEXIS_NEXIS_STATUS.refer) {
            this.$router.push({
                name: 'registerAdditionalDetails',
                query: { token: this.token },
            });
        }
    }
}
