import dateManager from '@/utils/time';
import { EventFormType } from '../types';

const formatEventDates: (event: EventFormType) => EventFormType = (event) => {
    const dateFormat = dateManager.getDayTimeFormatUtc();

    return {
        ...event,
        campaignDateFrom: dateManager.getDateTime(event.campaignDateFrom, {
            returnFormat: dateFormat,
        }),
        campaignDateTo: dateManager.getDateTime(event.campaignDateTo, { returnFormat: dateFormat }),
        eventDateFrom: dateManager.getDateTime(event.eventDateFrom, { returnFormat: dateFormat }),
        eventDateTo: dateManager.getDateTime(event.eventDateTo, { returnFormat: dateFormat }),
    };
};

export default formatEventDates;
