
import { Vue, Component, Prop } from 'vue-property-decorator';
import dateManager from '@/utils/time';
import DatePicker from './components/DatePicker/index.vue';
import DateTime from './time.vue';
import DateYearSelect from './yearSelect.vue';
import DateTimeSelect from './timeSelect.vue';
import DateYear from './year.vue';
import { DATE_PICKER_TYPES } from './constants';

@Component({
    components: {
        DatePicker,
        DateTime,
        DateYearSelect,
        DateYear,
        DateTimeSelect,
    },
})
export default class DatePickerDateTimeComponent extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: false }) private disableArrow!: boolean;

    @Prop({ required: true }) private value!: string;

    @Prop({ required: false }) private startDate!: string;

    @Prop({ default: '' }) private popupClasses!: string[];

    @Prop({ required: true }) private formattedVal!: string;

    @Prop({ required: true }) private originalVal!: string;

    @Prop({ required: true }) private timeValue!: string;

    @Prop({ required: true }) private yearValue!: string;

    @Prop({ default: true }) private timezoneDisabled!: boolean;

    @Prop({ default: true }) private yearEnabled!: boolean;

    @Prop({ default: false }) private active!: boolean;

    @Prop() private yearType!: string;

    @Prop() private timeSelectInterval!: number;

    @Prop() private minDate!: string;

    @Prop() private maxDate!: string;

    @Prop() private timeType!: string;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    get displayTimeSelect() {
        return this.timeType === this.dateTypes.timeSelect;
    }

    get formattedMinDate() {
        return dateManager.getDateTime(this.minDate, { returnFormat: 'YYYY-MM-DD' });
    }

    get formattedMaxDate() {
        return dateManager.getDateTime(this.maxDate, { returnFormat: 'YYYY-MM-DD' });
    }

    elementOnBlur(el: any) {
        this.$emit('onBlur', el);
    }

    elementOnFocus(el: any) {
        if (!this.disabled) {
            this.$emit('onFocus', el);
            this.$emit('onClick', el);
        }
    }

    formattedDateTimeValue(): string {
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.formattedVal);

        if (dateNoTimeZone) {
            if (this.yearEnabled) {
                return dateManager.getDateTime(dateNoTimeZone, { returnFormat: 'DD-MM-YYYY' });
            }

            return dateManager.getDateTime(dateNoTimeZone, { returnFormat: 'DD-MM-YYYY HH:mm' });
        }

        return '';
    }

    handleTimeUpdate(val: string) {
        this.$emit('onTimeUpdate', val);
    }

    handleDateUpdate(val: any) {
        this.$emit('onDateUpdate', val);
    }

    handleYearUpdate(val: any) {
        this.$emit('onYearUpdate', val);
    }

    handleTextDateUpdate(val: string) {
        this.$emit('onTextDateUpdate', val);
    }

    handleTimezoneUpdate(val: string) {
        const zone = dateManager.getTimeZoneRegex(val);
        const dateNoTimeZone = dateManager.getDateTimeWithoutTimezone(this.originalVal);
        if (zone) {
            const formattedDate = `${dateManager.getDateTime(dateNoTimeZone, { returnFormat: 'YYYY-MM-DDTHH:mm' })}${zone[0]}`;

            this.$emit('input', formattedDate);
        }
    }

    handleClose() {
        this.$emit('close');
    }

    handleOnClick(e: Node) {
        if (!this.disabled) {
            this.$emit('onClick', e);
        }
    }
}
