const enrollee = `type Enrollee {
    id: String!,
    parentId: String,
    userId: String,
    totals: Volume!,
    qualifiedLeaderLegsCount: Int!,
    qualifiedBranchesCount: Int!,
    leaderLegsCount: Int!,
    risingStarsCount: Int!,
    ambassadorLegsCount: Int!,
    personalRecruitsCount: Int!,
    directDownline(limit: Int!, offset: Int!, aofFilter: String): [Enrollee],
    searchDirectDownline(query: String!, aofFilter: String): [Enrollee],
    directDownlineCount(aofFilter: String): Int!,
    leaderLegs(limit: Int!, offset: Int!): [Enrollee],
    searchLeaderLegs(query: String!): [Enrollee],
    risingStars(sort: String, limit: Int!, offset: Int!, type: String, sortOrder: String): [Enrollee],
    searchRisingStars(query: String!): [Enrollee],
    stats(runId: String): [Stats],
    rankId: String!,
    rank(runId: String): Rank!,
    payRank: Rank!,
    highestAchievedRank: Rank!,
    email: String,
    phoneNumber: String!,
    secondPhoneNumber: String,
    address: String!,
    secondAddress: String,
    town: String,
    county: String,
    postCode: String,
    country: String,
    region: String,
    searchCustomers(query: String!, offset: Int, limit: Int, direct: Boolean): [Enrollee],
    searchCustomersCount(query: String!, direct: Boolean): Int!,
    uplineLeader: Enrollee,
    totalSales: String!,
    additionalCommission: String!,
    ctb: String!,
    leadershipBonus: String!,
    centralTeamBonus: String!,
    paidForKit: Boolean,
    agreedToTermsAndConditions: Boolean!,
    searchHosts(query: String!, offset: Int, limit: Int): [Enrollee],
    searchHostsCount(query: String!): Int!,
    ambassadorsCountsByActivityLevel (type: String, from: DateTime, to: DateTime): [Stats],
    qualifiedAmbassadorsCountByLevel (from: DateTime, to: DateTime): [Stats],
    bankDetails: BankDetails,
    parent: Enrollee,
    joinDate: String,
    mostRecentOrderDate: String,
    kitPurchaseDate: String,
    newStarters (date: DateTime!, type: String!, offset: Int, limit: Int, dateEnd: DateTime): [Enrollee],
    searchNewStarters(query: String!, date: DateTime!, type: String!, dateEnd: DateTime): [Enrollee],
    centralTeamCount: Int!
    profilePicture: Asset
    organizationCount: Int!,
    newStartersCount(date: DateTime!, type: String!, query: String, dateEnd: DateTime): Int!,
    qualifiedLeaderBranchIds: [String],
    qualifiedOrgLeaderCount: Int!,
    qualifiedFrontlineLeadersCount: Int!,
    activeOnFileDirectEnrollees: Int!,
    newStartersCommissionEngine(date: DateTime!, type: String!, limit: Int!, offset: Int!): [Enrollee],
    newStartersCountCommissionEngine(date: DateTime!, type: String!): Int!,
    vanityUrl: String!,
    shopUrl: String!,
    shopLoginUrl: String!,
    customerNumber: String,
    canRegister: Boolean,
    firstName: String,
    lastName: String,
    birthDate: String,
    searchOrders(query: String!, offset: Int, limit: Int): [Sale],
    searchOrdersCount(query: String!): Int!,
    incomeSummary(from: DateTime!, to: DateTime!): Income,
    lexisNexisStatus: String,
    shadow: Boolean!,
    hidden: Boolean,
    commissionStatements: [CommissionStatement],
    balance: Stats,
    transactions (offset: Int, limit: Int): [Transaction],
    transactionsCount: Int!,
    participatedRuns(filterDateFrom: String, includeUnlocked: Boolean): [Run],
    reports(runId: String, withDateDependant: Boolean): [Report],
    editable: Boolean!,
    businessDetails: BusinessDetails,
    suspension: Boolean!,
    milestones(filter: MilestoneFilterInput): [Milestone],
    aOFStatus: String,
    agreements: [Agreement],
    runTotalsForPeriod(limit: Int, offset: Int, from: DateTime, to: DateTime): [RunTotal],
    activeGroupEnrolleesCount: Int!,
}`;

const volume = `type Volume {
    pv: Float!,
    gv: Float!,
    ov: Float!,
    spa_gv: Float!,
}`;

const agreements = `type Agreement {
    type: String!,
    agreed: Boolean!,
    agreedAt: String,
}`;

const businessDetails = `type BusinessDetails {
    businessAccount: Boolean!,
    businessName: String,
    businessAddress: String,
    eoriNumber: String,
    tinNumber: String,
    vatNumber: String,
}`;

const milestone = `type Milestone {
    type: String!,
    category: String!,
    key: String!,
    achieved: Boolean!,
    achievedAt: String,
    unachievable: Boolean!,
    state: [Stats]!,
    startDate: String!
    endDate: String!
}`;

const runTotal = `type RunTotal {
    id: String!,
    runId: String!,
    from: String!,
    to: String!,
    enrolleeMetrics: RunTotalMetrics!
}`;

const runTotalMetrics = `type RunTotalMetrics {
    pv: String!
}`;

const enrolleeQuery = `
enrollee(id: String): Enrollee
`;

const milestoneFilterInput = `input MilestoneFilterInput {
    type: String,
    period: Int
}`;

const businessDetailsInput = `input BusinessDetailsInput {
    businessName: String,
    businessAddress: String,
    businessAddressLookup: String,
    eoriNumber: String,
    tinNumber: String,
    vatNumber: String,
}`;

export {
    enrollee, enrolleeQuery, volume, agreements, businessDetails, milestone,
    milestoneFilterInput, businessDetailsInput, runTotal, runTotalMetrics,
};
