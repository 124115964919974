import { CountriesType } from '@/modules/Event/types';

const countries: () => CountriesType[] = () => ([
    {
        shortLabel: 'CI',
        longLabel: 'Channel Islands',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'FR',
        longLabel: 'France',
        phoneCode: '+33',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'DE',
        longLabel: 'Germany',
        phoneCode: '+49',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'IM',
        longLabel: 'Isle of Man',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'NIR',
        longLabel: 'Northern Ireland',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'ROI',
        longLabel: 'Republic of Ireland',
        phoneCode: '+353',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'IT',
        longLabel: 'Mainland Italy',
        phoneCode: '+39',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Mainland Spain',
        phoneCode: '+34',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'PL',
        longLabel: 'Poland',
        phoneCode: '+48',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'RO',
        longLabel: 'Romania',
        phoneCode: '+40',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slovakia',
        phoneCode: '+421',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'GB',
        longLabel: 'United Kingdom',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
]);

const regions: () => CountriesType[] = () => ([
    {
        shortLabel: 'CI',
        longLabel: 'Channel Islands',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'FR',
        longLabel: 'France',
        phoneCode: '+33',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'DE',
        longLabel: 'Germany',
        phoneCode: '+49',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'IM',
        longLabel: 'Isle of Man',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'NIR',
        longLabel: 'Northern Ireland',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
    {
        shortLabel: 'ROI',
        longLabel: 'Republic of Ireland',
        phoneCode: '+353',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'IT',
        longLabel: 'Mainland Italy',
        phoneCode: '+39',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'ESP',
        longLabel: 'Mainland Spain',
        phoneCode: '+34',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'PL',
        longLabel: 'Poland',
        phoneCode: '+48',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'RO',
        longLabel: 'Romania',
        phoneCode: '+40',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'SK',
        longLabel: 'Slovakia',
        phoneCode: '+421',
        requiredBusinessDetails: [
            'businessName',
            'businessAddress',
            'eoriNumber',
            'tinNumber',
        ],
        disabled: false,
    },
    {
        shortLabel: 'UK',
        longLabel: 'United Kingdom',
        phoneCode: '+44',
        requiredBusinessDetails: [],
        disabled: false,
    },
]);

export { countries, regions };
