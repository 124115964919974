
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import dateManager from '@/utils/time';
import utils from '../../lib/date';

@Component
export default class Calendar extends Vue {
    // Adapted from @lazy-copilot/datetimepicker
    @Prop({ required: true }) private id!: string;

    @Prop() private year!: number;

    @Prop() private month!: number;

    @Prop() private startDate!: string;

    @Prop() private endDate!: string;

    @Prop() private onChange!: Function;

    @Prop({ default: false }) private singleDate!: boolean;

    @Prop() private minDate!: string;

    @Prop() private maxDate!: string;

    private selectedDay: null = null;

    private isSelectingStartDay: boolean = true;

    private weekdays: string[] = utils.weekDayShortConfig;

    private innerStartDate: string = this.startDate;

    private innerEndDate: string = this.singleDate ? this.startDate : this.endDate;

    get startWeekday() {
        return dateManager.getWeekDay(`${this.year}-${this.month}-01`);
    }

    get daysCount() {
        return dateManager.getDaysInMonth(`${this.year}-${this.month}`);
    }

    isDateDisabled(day): boolean {
        const date = `${this.year}-${this.month}-${day}`;
        if (dateManager.isBefore(date, this.minDate)) {
            return true;
        }
        if (dateManager.isAfter(date, this.maxDate)) {
            return true;
        }
        return false;
    }

    callOnChange(returnData) {
        if (this.$listeners.onChange) {
            this.$emit('onChange', { ...returnData });
            return;
        }

        if (this.onChange) {
            this.onChange({ ...returnData });
        }
    }

    updateSelectingSingleDay(day) {
        if (!day) return;
        if (this.isDateDisabled(day)) return;

        const {
            year, month,
        } = this;

        const currentDay = `${year}-${month}-${day}`;

        this.innerStartDate = currentDay;
        this.innerEndDate = currentDay;
        this.selectedDay = day;
        this.callOnChange({
            selectedDay: currentDay,
            startDate: currentDay,
        });
    }

    @Watch('startDate')
    handleDateUpdate() {
        const day = dateManager.getDateTime(this.startDate, { returnFormat: 'DD' });
        this.updateSelectingDay(day);
    }

    updateSelectingDay(day) {
        if (!day) return;
        if (this.isDateDisabled(day)) return;

        const {
            year, month, innerStartDate,
            isSelectingStartDay,
        } = this;

        const currentDay = `${year}-${month}-${day}`;

        // reset
        if (
            isSelectingStartDay
    || (!isSelectingStartDay && dateManager.isBefore(currentDay, innerStartDate))
        ) {
            this.innerStartDate = currentDay;
            this.isSelectingStartDay = false;
        } else {
            this.isSelectingStartDay = true;
        }

        this.innerEndDate = currentDay;
        this.selectedDay = day;

        this.callOnChange({
            selectedDay: currentDay,
            startDate: this.innerStartDate,
            endDate: this.innerEndDate,
        });
    }

    getDayStyle(day) {
        const {
            year, month, innerStartDate, innerEndDate,
        } = this;

        const currentDay: string = `${year}-${month}-${day}`;

        if (this.isDateDisabled(day)) return 'Calendar__Disabled';
        if (dateManager.isSameDay(currentDay, innerStartDate)) return 'Calendar__InnerStartDate';
        if (dateManager.isSameDay(currentDay, innerEndDate)) return 'Calendar__InnerEndDate';
        if (this.isBetweenDays(innerStartDate, innerEndDate, currentDay)) return 'Calendar__Between';
        if (this.isToday(currentDay)) return 'Calendar__Today';

        return '';
    }

    isToday(otherDay) {
        const today = dateManager.getCurrentDate();
        return dateManager.isSameDay(today, otherDay);
    }

    isBetweenDays(smallDay, bigDay, currentDay) {
        return dateManager.isBefore(currentDay, bigDay)
         && dateManager.isAfter(currentDay, smallDay);
    }
}
