// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { NewStartersType } from './types';
import parentObject from './reusable/listItemEnrolleeParent';

export default class GetNewStartersQuery extends
    AbstractQueryResource<GetNewStartersResultType|GetNewStartersSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;
        return `query ${this.getName()}($date: DateTime!, $dateEnd: DateTime, $type: String!${type === '' ? ', $offset: Int!, $limit: Int!' : ', $query: String'}) {
            profile {
                ${type === '' ? 'newStarters' : 'searchNewStarters'}(date: $date, type: $type, dateEnd: $dateEnd, ${type === '' ? 'offset: $offset, limit: $limit' : 'query: $query'}) {
                    id,
                    email,
                    phoneNumber,
                    address,
                    secondPhoneNumber,
                    secondAddress,
                    county,
                    country,
                    region,
                    town,
                    postCode,
                    ${parentObject.getQuery(true)},
                    firstName,
                    lastName,
                    kitPurchaseDate,
                    joinDate,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    highestAchievedRank {
                        id,
                        label,
                        power,
                    },
                    aOFStatus,
                    stats {
                        metric,
                        value,
                    },
                    milestones(filter: { period: 0, type: "quick_start" }) {
                        type,
                        category,
                        key,
                        achieved,
                        achievedAt,
                        unachievable,
                        state {
                            metric,
                            value,
                        },
                        startDate,
                        endDate,
                    },
                },
                ${type === '' ? 'newStartersCount(date: $date, type: $type, dateEnd: $dateEnd),' : ''}
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getNewStarters';
    }
}

export type GetNewStartersResultType = {
    profile: {
        newStarters: NewStartersType[];
        newStartersCount: number;
    }
}

export type GetNewStartersSearchResultType = {
    profile: {
        searchNewStarters: NewStartersType[];
    }
}

export type NewStartersParamsType = {
    date: string;
    type: string;
    offset?: number;
    limit?: number;
    dateEnd: string|null;
}

export type NewStartersSearchParamsType = NewStartersParamsType & {
    query: string|null;
}
