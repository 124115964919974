import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import dateManager from '@/utils/time';
import { AmbassadorsCountsParamsType, GetAmbassadorsCountsResultType } from '@/api/graphQL/graphNodes/GetAmbassadorsCountsQuery';
import BusinessShapeRepository from './services/BusinessShapeRepository';
import { defaultBusinessShapeData } from './defaults';
import { BusinessShapesType } from './types';
import { BUSINESS_SHAPE_TYPE } from './constants';

@Module({
    namespaced: true, dynamic: true, store, name: 'businessShape',
})
@AutoMutations
export class BusinessShape extends VuexModule {
    private loading: boolean = false;

    private activeBusinessShapeData: BusinessShapesType
        = defaultBusinessShapeData();

    @Mutation
    setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    setActiveBusinessShapeData(val: BusinessShapesType) {
        this.activeBusinessShapeData = val;
    }

    @Action()
    public async getActiveAmbassadorsCounts(id: string) {
        try {
            this.setLoading(true);

            const ambassadorsCountsParams: AmbassadorsCountsParamsType = await this
                .getQueryParams(id);
            const response: GetAmbassadorsCountsResultType = await BusinessShapeRepository
                .getAmbassadorsCounts(ambassadorsCountsParams, BUSINESS_SHAPE_TYPE.active);

            this.setActiveBusinessShapeData({
                active: response.active.ambassadorsCountsByActivityLevel!,
                activeOnFile: response.activeOnFile.ambassadorsCountsByActivityLevel!,
                inactive: response.inactive.ambassadorsCountsByActivityLevel!,
            });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getQueryParams(id: string): Promise<AmbassadorsCountsParamsType> {
        return {
            id,
            from: dateManager.getFirstDayOfMonth({ offset: -1 }),
            to: dateManager.getLastDayOfMonth({ offset: -1 }),
        };
    }

    @Action()
    public async getAmbassadorsCounts(id: string) {
        await Promise.all([
            this.getActiveAmbassadorsCounts(id),
        ])
            .catch((e) => {
                throw e;
            });
    }
}

export default getModule(BusinessShape);
