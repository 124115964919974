
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import dateManager from '@/utils/time';
import { Get } from '@/utils/vuex-module-mutators';
import calendar from '@/modules/Calendar';
import events from '@/modules/Calendar/Events';
import { CalendarObject } from '@/modules/Calendar/Events/types';

@Component
export default class CalendarMobileDay extends Vue {
    @Prop() private id!: string;

    @Prop() private thisDay!: boolean;

    @Get(calendar) private calendarDate!: string;

    @Get(events) days!: CalendarObject;

    get containerClasses() {
        return [
            'CalendarMobileDay__Container',
            this.thisDay ? 'CalendarMobileDay__CurrentContainer' : '',
        ];
    }

    get dayContainerClasses() {
        return [
            'CalendarMobileDay__DayNumberElement',
        ];
    }

    get day(): string {
        return dateManager.getDateTime(this.id, { returnFormat: 'DD' });
    }

    get thisMonth(): boolean {
        return dateManager.getDateTime(this.id, { returnFormat: 'MM' }) === dateManager.getDateTime(this.calendarDate, { returnFormat: 'MM' });
    }

    get withEvents(): boolean {
        return this.days[this.id].events.length > 0;
    }

    get dayClasses() {
        return [
            'CalendarMobileDay__DayWithEventsContainer',
            this.withEvents ? 'CalendarMobileDay__DayWithEvents' : '',
        ];
    }
}
