
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { EventViewType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { EVENT_DATE_FORMAT, EVENT_TIME_FORMAT } from '@/modules/Event/constants';
import env from '@/environment';
import SocialButtons from '@/projectComponents/socialButtons/index.vue';
import eventView from '@/modules/Event/EventView';
import EventGuests from './guests.vue';

@Component({
    components: {
        PropertyInfoDisplay,
        EventGuests,
        SocialButtons,
    },
})
export default class EventInfoView extends Vue {
    @Prop({ required: true }) private event!: EventViewType;

    @Get(eventView) private showShopUrlQr!: boolean;

    get shopUrl(): string {
        const eventId = this.event.id;

        return `${env.VUE_APP_SHOP_URL}/gb/uevent?event=${eventId}`;
    }

    get campaignDate() {
        const dateFrom = dateManager.getDateTime(this.event.campaignDateFrom, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });
        let dateTo = dateManager.getDateTime(this.event.campaignDateTo, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });

        if (dateManager
            .getDateTime(this.event.campaignDateFrom, { returnFormat: EVENT_DATE_FORMAT })
            === dateManager
                .getDateTime(this.event.campaignDateTo, { returnFormat: EVENT_DATE_FORMAT })) {
            dateTo = dateManager
                .getDateTime(this.event.campaignDateTo, { returnFormat: EVENT_TIME_FORMAT });
            return `${dateFrom} - ${dateTo}`;
        }

        return `${dateFrom} - ${dateTo}`;
    }

    get eventDate() {
        const dateFrom = dateManager.getDateTime(this.event.eventDateFrom, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });
        let dateTo = dateManager.getDateTime(this.event.eventDateTo, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });

        if (dateManager.getDateTime(this.event.eventDateFrom, { returnFormat: EVENT_DATE_FORMAT })
            === dateManager
                .getDateTime(this.event.eventDateTo, { returnFormat: EVENT_DATE_FORMAT })) {
            dateTo = dateManager
                .getDateTime(this.event.eventDateTo, { returnFormat: EVENT_TIME_FORMAT });
            return `${dateFrom} - ${dateTo}`;
        }

        return `${dateFrom} - ${dateTo}`;
    }

    get descriptionHtml() {
        const converter = new QuillDeltaToHtmlConverter(
            JSON.parse(this.event.description),
        );
        return converter.convert();
    }

    get titleClasses() {
        return [
            'EventView__Title',
            { 'EventView__EventTitle--cancelled': this.event.canceled },
        ];
    }

    handleShopQr() {
        eventView.toggleShowShopUrlQr();
    }
}
