import { EVENT_TYPE } from '@/modules/Calendar/constants';
import FunctionalityManager from '@/utils/functionality-manager';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AchievementAwardType, DashboardType } from './types';

export default class GetDashboardQuery extends
    AbstractQueryResource<GetDashboardResultType> {
    protected getQuery(): string {
        const withClub250: boolean = FunctionalityManager.shouldDisplay('club250');
        const runTotalsForPeriod: string = withClub250 ? `runTotalsForPeriod(limit: 12, from: $firstDayOfYear) {
            id,
            runId,
            enrolleeMetrics {
                pv
            },
            from,
            to
        }` : '';

        return `query ${this.getName()}($dateFrom: DateTime!, $runsDateFrom: String, $lastMonthDateFrom: DateTime!, $lastMonthDateTo: DateTime!${withClub250 ? ', $firstDayOfYear: DateTime!' : ''}) {
            profile {
                rank {
                    id,
                    label,
                    power,
                    requirements {
                        metric,
                        value
                    },
                    titleRequirements {
                        metric,
                        value
                    }
                },
                newAmbassadorsPersonal: newStartersCountCommissionEngine(date: $dateFrom, type: "personal"),
                newAmbassadorsInCentralTeam: newStartersCountCommissionEngine(date: $dateFrom, type: "central_team"),
                newAmbassadorsOrganizational: newStartersCountCommissionEngine(date: $dateFrom, type: "organisational"),
                organizationCount,
                centralTeamCount,
                activeOnFileDirectEnrollees,
                nextSeason: milestones(filter: { period: 0 }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    state {
                        metric,
                        value,
                    },
                },
                thisSeason: milestones(filter: { period: 1, type: "bdp" }) {
                    type,
                    category,
                    key,
                    achieved,
                    achievedAt,
                    unachievable,
                    state {
                        metric,
                        value,
                    },
                },
                participatedRuns(filterDateFrom: $runsDateFrom, includeUnlocked: true) {
                    id,
                    label,
                    from,
                    to,
                    locked
                },
                ${runTotalsForPeriod}
            },
            dashboardAchievementAwards {
                type,
                title,
                tooltip,
                description,
                leaderboard {
                    position,
                    previousPosition,
                    enrolleeId,
                    name,
                    metricValue,
                }
            },
            thisMonth: eventsTotals(type: "${EVENT_TYPE.organisational_and_personal}") {
                finishedEvents,
                bookedEvents,
                avgSalesPerEvent,
                totalSales
            },
            lastMonth: eventsTotals(dateFrom: $lastMonthDateFrom, dateTo: $lastMonthDateTo,
             type: "${EVENT_TYPE.organisational_and_personal}") {
                finishedEvents,
            },
        }`;
    }

    protected getCacheCondition() {
        return 1;
    }

    protected getName(): string {
        return 'getDashboard';
    }
}

export type GetDashboardResultType = {
    profile: DashboardType;
    dashboardAchievementAwards: AchievementAwardType[];
    thisMonth: {
        finishedEvents: number;
        bookedEvents: number;
        avgSalesPerEvent: string;
        totalSales: number;
    };
    lastMonth: {
        finishedEvents: number;
    };
}

export type GetDashboardParamsType = {
    dateFrom: string;
    lastMonthDateFrom: string;
    lastMonthDateTo: string;
    firstDayOfYear: string;
    runsDateFrom: string;
}
