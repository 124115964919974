
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineExplorerType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';

@Component
export default class DownlineCurrentInfo extends Vue {
    @Prop() private leg!: DownlineExplorerType;

    @Prop({ default: true }) private displayTitle!: boolean;

    get address(): string {
        const {
            address, secondAddress, postCode,
            town, county, country,
        } = this.leg;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!town && town !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${town}`;
        }

        if (!!postCode && postCode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postCode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }

    get withSecondPhoneNumber(): boolean {
        const { secondPhoneNumber } = this.leg;
        return !!secondPhoneNumber && secondPhoneNumber !== '';
    }

    get joinDate(): string {
        return dateManager.getDateTime(this.leg.joinDate, {
            returnFormat: dateManager.getDateFormatUK(),
        });
    }

    checkIfDataAvailable(data: any): boolean {
        if (!data || data === '') {
            return false;
        }

        return true;
    }
}
