
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DownlineParentType } from '@/api/graphQL/graphNodes/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import dateManager from '@/utils/time';
import FunctionalityManager from '@/utils/functionality-manager';
import { LegType } from '@/modules/types';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class DownlineContacts extends Vue {
    @Prop() private leg!: LegType;

    @Prop({ default: '' }) private viewName!: string;

    @Prop({ default: null }) private parent!: DownlineParentType|null;

    @Prop({ default: true }) private withSponsorDetails!: boolean;

    get sponsorSectionClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            { DownlineContacts__SponsorContainer: this.withSponsorDetails && this.displayMentor },
        ];
    }

    get isMyCustomers(): boolean {
        return this.viewName === 'myCustomers';
    }

    get displayMentor(): boolean {
        return !this.isMyCustomers || (this.isMyCustomers && FunctionalityManager.shouldDisplay('myCustomersMentor'));
    }

    get withSecondPhoneNumber(): boolean {
        const { secondPhoneNumber } = this.leg;
        return !!secondPhoneNumber && secondPhoneNumber !== '';
    }

    get sponsorName(): string {
        const { parent } = this;
        if (parent && parent.firstName && parent.lastName) {
            return `${parent.firstName} ${parent.lastName}`;
        }

        return '';
    }

    get sponsorId(): string {
        const { parent } = this;
        if (parent && parent.id) {
            return parent.id;
        }

        return '';
    }

    get address(): string {
        const {
            address, secondAddress, postCode,
            town, county, country,
        } = this.leg;

        let res = address;

        if (!!secondAddress && secondAddress !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${secondAddress}`;
        }

        if (!!town && town !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${town}`;
        }

        if (!!postCode && postCode !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${postCode}`;
        }

        if (!!county && county !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${county}`;
        }

        if (!!country && country !== '') {
            res = `${res}${res !== '' ? ',' : ''} ${country}`;
        }

        return res;
    }

    get joinDate(): string {
        return dateManager.getDateTime(this.leg.joinDate, {
            returnFormat: dateManager.getDateFormatUK(),
        });
    }

    get mostRecentOrderDate(): string {
        const { mostRecentOrderDate } = this.leg;
        return mostRecentOrderDate ? dateManager.getDateTime(mostRecentOrderDate, { returnFormat: dateManager.getDateFormatUK() }) : '-';
    }

    handleSponsorClick() {
        if (this.withSponsorDetails && this.displayMentor) {
            this.$emit('openSponsor', this.leg.id);
        }
    }
}
