
import { Vue, Component, Prop } from 'vue-property-decorator';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { EventType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import { EVENT_DATE_FORMAT, EVENT_TIME_FORMAT } from '@/modules/Event/constants';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventInvitationInfo extends Vue {
    @Prop({ required: true }) private event!: EventType;

    @Prop({ required: true }) private hostessInvitation!: boolean;

    get descriptionHtml() {
        const converter = new QuillDeltaToHtmlConverter(JSON.parse(this.event.description));
        return converter.convert();
    }

    get titleClasses() {
        return [
            { 'EventInvitationView__Title--canceled': this.event.canceled },
        ];
    }

    displayDate(from, to): string {
        const dateFrom = dateManager.getDateTime(from, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });
        let dateTo = dateManager.getDateTime(to, { returnFormat: `${EVENT_DATE_FORMAT} ${EVENT_TIME_FORMAT}` });

        if (dateManager.isSameDay(from, to)) {
            dateTo = dateManager.getDateTime(to, { returnFormat: EVENT_TIME_FORMAT });
        }

        return `${dateFrom} - ${dateTo}`;
    }
}
