
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import profile from '@/modules/Profile';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONES_TOOLTIP_TYPES, MILESTONE_TYPE } from '@/modules/Dashboard/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import Achievement from '@/projectComponents/achievement/index.vue';
import resolveStatus from '@/projectComponents/achievement/resolveStatus';

@Component({
    components: {
        Achievement,
    },
})
export default class PathwayQuickStart extends Vue {
    @Get(dashboard, 'data.nextSeason') private nextSeason!: MilestoneType[];

    @Get(profile, 'data.region') private region!: string;

    get maxItemsInRow(): number {
        return 3;
    }

    get quickStartList(): MilestoneType[] {
        if (this.isGB) {
            return this.nextSeason.filter((item) => item.type === MILESTONE_TYPE.quickStart
            && ((item.key.includes('uk') && !item.key.includes('non_uk')) || !item.key.includes('uk')));
        }

        return this.nextSeason.filter((item) => item.type === MILESTONE_TYPE.quickStart
         && (item.key.includes('non_uk') || !item.key.includes('uk')));
    }

    get formattedList(): Array<MilestoneType[]> {
        const res: Array<MilestoneType[]> = [];
        let rowIndex = 0;
        let itemsInRow = 0;

        this.quickStartList.forEach((item) => {
            if (!res[rowIndex]) {
                res[rowIndex] = [];
            }

            res[rowIndex].push(item);

            if (itemsInRow === 2) {
                itemsInRow = 0;
                ++rowIndex;
            } else {
                ++itemsInRow;
            }
        });

        return res;
    }

    get isGB(): boolean {
        return this.region === COUNTRY_SELECT_OPTIONS.gb;
    }

    get tooltipContent(): string {
        return WildcardManager.pathwayTooltipResolver(MILESTONES_TOOLTIP_TYPES.quick);
    }

    status(milestone: MilestoneType): string {
        return resolveStatus(milestone);
    }

    resolveValue(type: string): string {
        const res = WildcardManager.quickStartLabelResolver(type);

        return res.short;
    }
}
