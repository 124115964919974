
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import { OrderType } from '@/api/graphQL/graphNodes/types';
import profile from '@/modules/Profile';
import dateManager from '@/utils/time';
import CurrencyPrefix from '@/utils/currencyPrefix';
import OrderHeaderTitle from '@/projectComponents/orderHeaderTitle/index.vue';
import system from '@/modules/System';
import auth from '@/modules/Auth';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import ProductItem from './ProductItem/index.vue';
import ProductTable from './ProductTable/index.vue';
import OrderValues from '../orderValues/index.vue';

@Component({
    components: {
        ProductItem,
        OrderHeaderTitle,
        OrderValues,
        ProductTable,
    },
})
export default class OrdersListItem extends Vue {
    @Get(profile, 'originalData.id') private profileId!: string;

    @Get(system) private screenType!: string;

    @Prop() private id!: string;

    @Prop() private order!: OrderType;

    @Prop() private region!: string;

    @Prop() private expandedList!: string[];

    @Prop({ default: false }) private showCustomerName!: boolean;

    get commissionableOrder() {
        if (this.order.enrollee.id === this.profileId) {
            return true;
        }
        return false;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get customerFullName() {
        if (this.order.customer) {
            const { customer } = this.order;
            return `${customer.firstName} ${customer.lastName}`;
        }
        return '';
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get orderNo(): string {
        return this.order.id;
    }

    get orderDate(): string {
        return dateManager
            .getDateTime(this.order.date, { returnFormat: dateManager.getDayTimeFormatUK() });
    }

    get expanded(): boolean {
        const { id } = this.order;
        return this.expandedList.findIndex((item) => item === id) !== -1;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get orderValue(): string {
        const { order, isHostess } = this;
        const { value, grossValue } = order;
        const res = isHostess ? grossValue : value;

        return String(res);
    }

    format2DecimalPoints(val): string {
        return Number(val).toFixed(2);
    }

    handleExpandedToggle() {
        this.$emit('expandedToggle', this.order.id);
    }
}
