import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { GetDashboardResultType } from '@/api/graphQL/graphNodes/GetDashboardQuery';
import {
    AchievementAwardType, DashboardType, RunTotalType,
} from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import dateManager from '@/utils/time';
import dashboardRepository from './services/dashboardRepository';
import { initialDashboardData } from './defaults';
import statsModule from './stats';

@Module({
    namespaced: true, dynamic: true, store, name: 'dashboard',
})
@AutoMutations
export class Dashboard extends VuexModule {
    private data: DashboardType = initialDashboardData();

    private loaded: boolean = false;

    private loading: boolean = false;

    private activeTab: string = 'currentRank';

    private awards: AchievementAwardType[] = [];

    private runTotalsForPeriod: RunTotalType[] = [];

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setDashboardData(val: DashboardType) {
        this.data = { ...val };
    }

    @Mutation
    public setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    @Mutation
    public setAwards(awards: AchievementAwardType[]) {
        this.awards = [...awards];
    }

    @Mutation
    public setRunTotalsForPeriod(val: RunTotalType[] = []) {
        this.runTotalsForPeriod = [...val];
    }

    @Action()
    public async getDashboardData() {
        try {
            this.setLoading(true);
            const res: GetDashboardResultType = await dashboardRepository.getData({
                dateFrom: dateManager.getFirstDayOfMonth(),
                lastMonthDateFrom: dateManager.getFirstDayOfMonth({ offset: -1 }),
                lastMonthDateTo: dateManager.getLastDayOfMonth({ offset: -1 }),
                firstDayOfYear: `${dateManager.getCurrentDate({ returnFormat: 'YYYY' })}-01-01`,
                runsDateFrom: dateManager.getDateWithOffset(-1, `${dateManager.getCurrentDate({ returnFormat: 'YYYY-MM' })}-01 00:00:00`, {
                    returnFormat: dateManager.getDayTimeFormat(),
                    type: 'year',
                }),
            });

            const profile = ObjectProcessor.objectMerge(initialDashboardData(), res.profile);

            this.setAwards(res.dashboardAchievementAwards);
            if (profile.runTotalsForPeriod) {
                this.setRunTotalsForPeriod(profile.runTotalsForPeriod);
            }

            this.setDashboardData({
                ...profile,
                leadershipBonus: '0.00',
                additionalCommission: '0.00',
                centralTeamBonus: '0.00',
                performanceData: {
                    ...res.thisMonth,
                    lastMonthFinishedEvents: res.lastMonth.finishedEvents,
                },
            });

            this.setLoaded(true);

            if (res.profile.participatedRuns) {
                statsModule.setSelectedRun(res.profile.participatedRuns[0].id);
            }
            statsModule.getStats();
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Dashboard);
