import FunctionalityManager from '@/utils/functionality-manager';
import { MyCustomersType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import parentObject from './reusable/listItemEnrolleeParent';

export default class GetCustomersQuery extends AbstractQueryResource<GetMyCustomersResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($query: String!, $offset: Int, $limit: Int, $direct: Boolean) {
            profile {
                searchCustomers(query: $query, offset: $offset, limit: $limit, direct: $direct) {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    secondPhoneNumber,
                    address,
                    secondAddress,
                    county,
                    country,
                    town,
                    postCode,
                    mostRecentOrderDate,
                    shadow,
                    email,
                    editable,
                    totalSales,
                    searchOrdersCount(query: ""),
                    ${FunctionalityManager.shouldDisplay('myCustomersMentor') ? parentObject.getQuery(false) : ''},
                },
                searchCustomersCount(query: $query, direct: $direct)
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getMyCustomers';
    }
}

export type GetMyCustomersResultType = {
    profile: {
        searchCustomers: MyCustomersType[];
        searchCustomersCount: number;
    };
}

export type GetMyCustomersParamsType = {
    offset: number;
    limit: number;
    query: string;
    direct: boolean;
}
